export const validEntry = (value: string) => {
  return value.length > 0 && value.includes('.') && value.includes('@');
};

const checkForAtSign = (value: string) => {
  if (value.includes('@')) return value;
  return `@${value}`;
};

const cleanUpEmail = (value: string) => {
  return value.replace(',', '').toLowerCase().trim();
};

export const emailArrayBuilder = (items: string[] = [], updated: string[] = []) => {
  items.forEach(item => {
    const split = item.split(/(,|\s|;)/);
    split.forEach(segment => {
      const email = cleanUpEmail(segment);
      if (email && email.length && !updated.includes(`@${email}`) && !updated.includes(email) && validEntry(email)) {
        updated.push(checkForAtSign(email));
      }
    });
  });
  return [...updated];
};

export const emailArrayBuilderWithErrors = (items: string[] = [], updated: TSFix[] = []) => {
  const invalidEmails: string[] = [];
  const duplicateEmails: string[] = [];
  items.forEach(item => {
    const email = cleanUpEmail(item);
    const duplicate = updated.includes(`@${email}`) || updated.includes(email);
    if (email && email.length && !duplicate && validEntry(email)) {
      updated.push(checkForAtSign(email));
    } else if (duplicate) {
      duplicateEmails.push(item);
    } else {
      invalidEmails.push(item);
    }
  });

  return [[...updated], invalidEmails, duplicateEmails];
};

export const validateEmail = (input = '') => {
  // regex from https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
  const re =
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(input.toLowerCase());
};
